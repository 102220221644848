import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavVisability } from "../store/StaticDataReducer";
import { hideWelcomeMsg } from "../store/StaticDataReducer";
import { loadSavedItems } from "../store/SavedItems/SavedItemsReducer";
function RoutersContainer({ children }) {
  const { collapse } = useSelector((state) => state.root.nav);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSavedItems());
    dispatch(hideWelcomeMsg());
  }, []);
  return (
    <section
      className="router-container"
      onClick={() => {
        if (collapse) {
          dispatch(setNavVisability(false));
        }
      }}
    >
      {children}
    </section>
  );
}

export default RoutersContainer;
