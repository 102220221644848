import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useSendContactInfoMutation } from "../store/Api/api";
function Footer({ isShop }) {
  const footerData = useSelector((state) => state.root.footer);
  const formInitialValues = footerData.form
    .map((item) => item.key)
    .reduce((a, b) => {
      a[b] = "";
      return a;
    }, {});
  const [sendContactInfo, { isLoading }] = useSendContactInfoMutation();
  const [formData, setFormData] = useState(formInitialValues);

  const servicesData = useSelector((state) => state.root.services);

  const inputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitAction = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const res = await sendContactInfo(formData).unwrap();
      console.log(res);
      setFormData(formInitialValues);
    } catch (error) {
      console.log(error);
    }
  };

  const generateFormItem = (formItem) => {
    const { label, type, key } = formItem;
    let labelElement = <label htmlFor={key}>{label}</label>;
    if (type === "textarea") {
      return (
        <div key={key} className="form-item text-area">
          {labelElement}

          <textarea
            id={key}
            onChange={(e) => inputChange(key, e.target.value)}
          ></textarea>
        </div>
      );
    } else {
      return (
        <div key={key} className="form-item">
          {labelElement}

          <input
            id={key}
            onChange={(e) => inputChange(key, e.target.value)}
            type={type}
            autoComplete="off"
            required
          />
        </div>
      );
    }
  };
  return (
    <footer className="footer" id="contact">
      <h3>{isShop ? footerData.shopTitle : footerData.title}</h3>
      <section className="form-container">
        <form onSubmit={submitAction}>
          {footerData.form.map((formItem) => generateFormItem(formItem))}
          <Button loading={isLoading} disabled={isLoading}>
            {footerData.buttonText}
          </Button>
        </form>
      </section>

      <section className="info-container">
        <article>
          <h5> {footerData.info.title}</h5>
          {footerData.info.data.map((infoItem) => (
            <p key={infoItem.text}>
              <i className={infoItem.icon}></i>
              {infoItem.text}
            </p>
          ))}
        </article>
        <article>
          <h5> {servicesData.footerTitle}</h5>
          <ul>
            {servicesData.data.map((serviceItem) => (
              <li key={serviceItem.name}>{serviceItem.name}</li>
            ))}
          </ul>
        </article>
      </section>
      <div className="copy-writer">{footerData.copyWriteText}</div>
    </footer>
  );
}

export default Footer;
