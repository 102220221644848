import React from "react";
import logo from "../assets/logo.png";
import BarLoader from "react-spinners/BarLoader";
import { useSelector } from "react-redux";
function Loader() {
  const fristLoader = useSelector((state) => state.root.fristLoader);
  const welcomeMsg = useSelector((state) => state.root.welcomeMsg);
  return (
    <section className="loader-container">
      <img src={logo} alt={"TopLink Logo"} />
      {fristLoader && <p className="welcome-msg">{welcomeMsg}</p>}
      <BarLoader color={"#fff"} />
    </section>
  );
}

export default Loader;
