import { Link } from "react-router-dom";
export const getLocalImageByName = (name) => {
  const images = require.context("../assets", true);
  return name && images(`./${name}`);
};

export const getImage = (url) => {
  return url?.startsWith("http") ? url : getLocalImageByName(url);
};
export const spanSpecificWords = (text, words) => {
  return text.split(" ").map((word) => {
    if (words.includes(word)) {
      return <span key={word}>{word + " "}</span>;
    } else {
      return `${word} `;
    }
  });
};

export const craeteHref = (statement, targetWord, route = "#") => {
  return statement.split(" ").map((word) => {
    if (targetWord === word.replace("__", " ")) {
      return (
        <Link key={word} to={route}>
          {targetWord + " "}
        </Link>
      );
    } else {
      return `${word} `;
    }
  });
};

export const getNumberFormat = (number) => Intl.NumberFormat().format(number);

export const getSavedItems = () => {
  const prevIds = localStorage.getItem("saved_ids")
    ? JSON.parse(localStorage.getItem("saved_ids"))
    : [];
  return prevIds;
};

export const saveProduct = (_id) => {
  const prevIds = getSavedItems();
  localStorage.setItem(
    "saved_ids",
    JSON.stringify([...new Set([...prevIds, _id])])
  );
};

export const unSaveProduct = (_id) => {
  const prevIds = getSavedItems();
  localStorage.setItem(
    "saved_ids",
    JSON.stringify(prevIds.filter((id) => id !== _id))
  );
};

export const isSaved = (_id) => {
  const prevIds = getSavedItems();
  return prevIds.find((id) => id === _id);
};
