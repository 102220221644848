import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nav: {
    links: [
      { value: "Home", route: "/" },
      { value: "About US", route: "/about-us" },
      { value: "Services", route: "/services" },
      { value: "Shop", route: "/shop" },
      { value: "Recent Work", route: "/recentwork" },
      { value: "Clients", route: "/clients" },
      // { value: "Partners", route: "/partners" },
      { value: "Contact Us", route: "/contactUs" },
    ],
    collapse: false,
  },
  home: {
    heroSectionTitle: ["Inspiring", " Tech Needs"],
    footerContent: {
      title: `TopLink can assist all of your business needs`,
      content: `Our experience is trusted by more than 52 grand organisations !`,
      btns: [
        { text: "SHOP NOW", route: "/shop" },
        { text: "SERVICES", route: "/services" },
      ],
    },
    aboutUs: {
      title: "About Us",
      description: `TopLink is leading Egyption company that provides high quality services and tech hardware infrastructure`,
      sections: [
        {
          title: "Building",
          details: ["Surveillance Cameras", "Fire Alarm Systems"],
        },
        {
          title: "Designing",
          details: ["Computer Networking", "Barcode Systems"],
        },
      ],
      bottomCaption:
        "Find Your Suitable Printers,inks and all Kinds of servers, labtops and computers in one place.",
      button: { text: "Read more about Us", route: "/about-us" },
    },

    intrgratedShopData: {
      title: "Integrated Hardware Shop",
      descOne: "Payment fixability .. High quality",
      descTwo: "Where you can find your new and used devices all in one place.",
      options: [
        "3D Printers",
        "Light Current Systems",
        "Printers",
        "PSG, PCs & Labtops",
        "Networks",
        "Interactive boards",
        "Server",
        "Smart Home System",
        "UPS",
        "Smart Factory",
      ],
      images: [
        "1.png",
        "2.png",
        "3.png",
        "4.png",
        "5.png",
        "6.png",
        "7.png",
        "8.png",
      ],
      button: { text: "SHOP NOW", route: "/shop" },
    },
    hardwareBrands: {
      title: `Hardware Brands`,
      description: `you can find in our shop`,
      brands: [
        "microsoft.png",
        "cisco.png",
        "samsung.png",
        "veeam.png",
        "hp.png",
        "dell.png",
        "lenovo.png",
      ],
    },
    partners: {
      title: "Our Partners",
      data: [
        "1.png",
        "2.png",
        "3.png",
        "4.png",
        "5.png",
        "6.png",
        "7.png",
        "8.png",
        "9.png",
        "10.png",
        "11.png",
      ],
    },
  },
  aboutusPage: {
    mainSection: {
      title: "Who We Are",
      details: [
        "TopLink is a leading Egyptian company that provides high quality services in the fields of Surveillance Camera and Fire Alarm Systems, Designing and building Computer Networking and Barcode Systems. We also provide printers, inks and all kinds of servers, laptops and computers.",
        "Always get your support with our fixable maintenance contracts.",
        "We challenge ourselves to grow and expand furthermore with the help of our combined talents.",
      ],
    },
    ourPlan: [
      {
        img_url: "mission.png",
        title: "Mission",
        content:
          "To provide high-quality technology solutions in software development, cloud operations and IT infrastructure solutions enabling our customers to focus and enhance their core business.",
      },
      {
        img_url: "vision.png",
        title: "Vision",
        content:
          "To be the leading provider of innovative technology solutions in the fields of software application development and enterprise IT infrastructure services.",
      },
      {
        img_url: "goals.png",
        title: "Goals",
        content:
          "To combine our experience in IT infrastructure and software development to build products & services that operate smoothly with challenging technology environments.",
      },
    ],
    ourStrengthPoints: {
      title: "WHY TO CHOOSE US",
      points: [
        {
          icon: "fa-regular fa-user",
          title: "Top-Quality Talents",
          description:
            "You will have access to the most highly-skilled and experienced talents who will fulfill your technology needs, and ensure the highest quality of services.",
        },
        {
          icon: "fa-regular fa-bell",
          title: "Better Communication",
          description:
            "We cover all time zones, and ready to accomodate diversified cultures to make our customers confident about smooth working with no conflicts",
        },
        {
          icon: "fa-regular fa-circle-check",
          title: "Compliance",
          description:
            "We implement policies & best practices into your organization’s procedures, tools & systems that reinforce your business goals.",
        },
        {
          icon: "fa-sharp fa-solid fa-dollar-sign",
          title: "Cost Efficiency",
          description:
            "We will get the job done with minimum input to bring you the maximum output, we can help you enhance productivity, and generate more profits.",
        },
        {
          icon: "fa-solid fa-server",
          title: "Scalability",
          description:
            "No matter how much your business growth capacity increases, we can still provide you with 100% support for your project/product.",
        },
        {
          icon: "fa-solid fa-handshake",
          title: "Commitment",
          description:
            "At TopLink, we understand that reliability is key, that's why our commitment to excellence is at the heart of everything we do.",
        },
      ],
    },
  },
  clients: {
    title: "Our Clients",
    pageTitle: `Our Honorable Clients`,
    description: `We are glad we have a great variety of experience in working with different industries as Medical Field, Tourism, Transports, Technology, Sports, Vehicles, Logistics and Nautical navigation. `,
    categories: [
      {
        name: "Mediacl Organization",
        data: [
          "1.png",
          "2.png",
          "3.png",
          "4.png",
          "5.png",
          "6.png",
          "7.png",
          "8.png",
          "9.png",
          "10.png",
          "11.png",
        ],
      },
      {
        name: "International Trade & Marine Transport",
        data: ["5.png", "6.png", "7.png", "8.png", "9.png", "10.png"],
      },
      {
        name: "Tourism & Sports",
        data: ["1.png", "2.png", "3.png", "4.png", "10.png", "11.png"],
      },
      {
        name: "Restaurants",
        data: ["1.png", "2.png", "4.png", "10.png", "9.png", "11.png"],
      },
      {
        name: "Tech Solutions & Electronics",
        data: ["1.png", "2.png", "4.png", "10.png", "9.png", "11.png"],
      },
      {
        name: "Paperwork Organizations",
        data: ["1.png", "2.png", "4.png", "10.png", "9.png", "11.png"],
      },
      {
        name: "Petroleum & Logistics",
        data: ["1.png", "2.png", "4.png", "10.png", "9.png", "11.png"],
      },
      {
        name: "Cars",
        data: ["1.png", "2.png"],
      },
      {
        name: "Architecture",
        data: ["1.png", "2.png"],
      },
    ],
  },
  services: {
    title: "Services",
    footerTitle: "Services & Solutions",
    pageTttle: `Our Services`,
    /* __ Concat Words To Replace With Href If Needed  */
    pageDescription: [
      `Custom software development services unleashing`,
      `the potential of businesses in every industry.Just tell us your idea & Contact__us now.`,
    ],
    cartHoverText: "Read More",
    data: [
      {
        name: "Queue System",
        description: `Boost your sales with the perfect customer flow.
        Queue management system for better waiting line experience in walk‑in locations.`,
        image_url: `queue.png`,
        bg_img_url: "queueSystem.png",
        technologies: ["Switching", "Wireless", "Routing", "Collaboration"],
      },
      {
        name: "Networking",
        description: `Active networking must balance the flexibility of a programmable network infrastructure against the safety and security requirements inherent in sharing that infrastructure. 
        Active Networking solutions include:
        Switching.
        Routing.`,
        image_url: `net.png`,
        bg_img_url: "Networking.png",
        technologies: ["Switching", "Wireless", "Routing", "Collaboration"],
      },
      {
        name: "Infrastructure",
        description: `Passive Networking solutions include:
        Design of networks Infrastructure Cabling including Copper and Fiber.
        Cables Pulling, termination & testing.
        Data Center preparation including all the needed civil work, Raised Floor, Fire Alarm, Firefighting, UPS and Air conditioning and more
        `,
        image_url: `infra.png`,
        bg_img_url: "infrastructure.png",
        technologies: ["Switching", "Wireless", "Routing", "Collaboration"],
      },
      {
        name: "Software",
        description: `These key areas of implementation guarantee our delivery of unparalleled technology solutions.
        Areas of Expertise:
        Custom Software Development
        Software Testing & QA
        Mobile & Web Development
        Maintenance & Support
        `,
        image_url: `softw.png`,
        bg_img_url: "smartHomes.png",
        technologies: ["Switching", "Wireless", "Routing", "Collaboration"],
      },
      {
        name: "Smart Home",
        description: `Smart home installation & setup, the convenient and stress-free solution to upgrade your home.
        Full home-control:
        Light system
        Air conditioners
        Electrical devices
        Curtains
        `,
        image_url: `smart11.png`,
        bg_img_url: "software.png",
        technologies: ["Switching", "Wireless", "Routing", "Collaboration"],
      },
    ],
  },
  recentWork: {
    title: "Recent Work",
    pageTitle: "Explore our progress",
    pageDescription: [
      "Toplink most recent with some of our honorable clients",
      "Just tell us your idea & Contact__us now. ",
    ],
    data: [
      {
        name: "Networking and Infrastructure",
        client: "Khalil Pharmacy",
        title: `Networking and Infrastructure for Khalil Pharmacys`,
        descriptions: [
          "Plan and build a complete, virtualized computing infrastructure at a co-located data center",
          "Implement business software including Microsoft Exchange.",
          "Integrate a local area network (LAN) at the corporation’s office.",
        ],
        image_url: "khalilPharmacy.png",
        properties: [
          {
            key: "Duration",
            value: "1 Month",
          },
          {
            key: "Target",
            value: [
              "Plan and build a complete, virtualized computing infrastructure at a co-located data center",
              "Implement business software including Microsoft Exchange.",
              "Integrate a local area network (LAN) at the corporation’s office.",
            ],
          },
        ],
      },
      {
        name: "Server Installation",
        client: "Hafez Royal",
        title: `Hafez Royal at Server Installation`,
        descriptions: [
          "Network and File Security",
          "Enhanced Reliability",
          "Centralization of Network Resources",
          "Backup Centralization",
          "Management of Virus Threats ",
        ],
        image_url: "HafezRoyal.png",
        properties: [
          {
            key: "Duration",
            value: "1 Month",
          },
          {
            key: "Target",
            value: [
              "Network and File Security",
              "Enhanced Reliability",
              "Centralization of Network Resources",
              "Backup Centralization",
              "Management of Virus Threats ",
            ],
          },
        ],
      },
      {
        name: "Smart Factory Setup",
        client: "Suez Company",
        title: `Smart Factory Setup Suez Company`,
        descriptions: [
          "Monitoring the timing of the machines and their electricity consumption, temperatures and humidity inside refrigerators and stores.",
          "Allows the owner and specialists to be alerted on the mobile phone in case of any malfunction",
        ],
        image_url: "Suez.png",
        properties: [
          {
            key: "Duration",
            value: "1 Month",
          },
          {
            key: "Target",
            value: [
              "Monitoring the timing of the machines and their electricity consumption, temperatures and humidity inside refrigerators and stores.",
              "Allows the owner and specialists to be alerted on the mobile phone in case of any malfunction",
            ],
          },
        ],
      },
      {
        name: "HR software System",
        client: "Andalusia Hospitals",
        title: `HR software System Andalusia Hospitals`,
        descriptions: [
          "Build HRIS, or human resources information system, is software designed to help businesses meet core HR needs and improve the productivity of both managers and employees.",
        ],
        image_url: "AndalusiaHospitals.png",
        properties: [
          {
            key: "Duration",
            value: "1 Month",
          },
          {
            key: "Target",
            value: [
              "Build HRIS, or human resources information system, is software designed to help businesses meet core HR needs and improve the productivity of both managers and employees.",
            ],
          },
        ],
      },
    ],
  },
  contactUs: {
    pageTitle: "What’s on your mind?",
    carts: [
      {
        data: [
          {
            img_url: "customer-service.png",
            title: "Talk with us",
            description: "We will surely help you on whatever you need",
            acrion: ["Call us on", "035416416 - 035449131"],
            button: {
              icon: "fa-brands fa-whatsapp",
              text: "WhatsApp",
              url: "https://wa.me/+201017174397",
            },
            different: true,
          },
          {
            img_url: "sales-team.png",
            title: "Talk to a member of our sales team",
            description:
              "We’ll help you find the right products & pricing for your business",
            acrion: ["Call our sales on", "010 0749 5995"],
            button: {
              icon: "fa-solid fa-envelope",
              text: "Send Email",
            },
          },
        ],
      },
      {
        data: [
          {
            img_url: "buy one.png",
            title: "Need to make a purchase?",
            description: "Let us help you placing your order",
            acrion: [
              "",
              "Email us with your order and your contact info and we’ll call you for your order confirmation.",
            ],
            button: {
              icon: "fa-solid fa-envelope",
              text: "Order Now ",
            },
          },
        ],
      },
    ],
  },
  footer: {
    title: "How Can We Help You?",
    shopTitle: "Tell us more about your project",
    form: [
      { label: "Your Name:", type: "text", key: "client_name" },
      { label: "Subject:", type: "text", key: "subject" },
      { label: "Your Email:", type: "email", key: "client_email" },
      { label: "Mobile Number:", type: "text", key: "client_mobile_no" },
      { label: "Description:", type: "textarea", key: "message" },
    ],
    buttonText: "SUBMIT",
    info: {
      title: "INFO",
      data: [
        { icon: "fa-solid fa-inbox", text: "sales@toplink-group.net" },
        { icon: "fa-solid fa-inbox", text: "purchasing@toplink-group.net" },
        { icon: "fa-solid fa-phone", text: "035416416 - 035449131" },
        { icon: "fa-brands fa-whatsapp", text: "010 1717 4397" },
        { icon: "fa-solid fa-handshake", text: "SALES: 010 0749 5995" },
        {
          icon: "fa-solid fa-location-dot",
          text: "5 St. Ahmed Aly, Cleopatra, Alexandria, Egypt",
        },
      ],
    },
    copyWriteText: "© 2023 TopLink Integrated Solutions, All rights reserved.",
  },
  shop: {
    pageTitle: "SHOP",
    categories: [
      { id: 1, name: "PCs & Laptops", image_url: "PCs & Laptops.png" },
      { id: 2, name: "Servers", image_url: "Servers.png" },
      { id: 3, name: "Printers", image_url: "Printers.png" },
      { id: 4, name: "UPS", image_url: "UPS.png" },
      { id: 5, name: "Networks", image_url: "Networks.png" },
      { id: 6, name: "Light Current", image_url: "Light Current.png" },
      {
        id: 7,
        name: "Interactive Boards",
        image_url: "Interactive Boards.png",
      },
      { id: 8, name: "3D Prniters", image_url: "3D Prniters.png" },
      { id: 9, name: "Smart Home Devices", image_url: "SmartHomeDevices.png" },
      { id: 10, name: "Smart Factorys", image_url: "Smart Factory.png" },
    ],
    products: {
      empty: {
        img_url: "no-product-found.png",
        title: "Oops, no items were added yet",
        description: "Contact__us for more info",
      },
      data: [
        {
          _id: "lenovo-labtop-1",
          name: 'Lenovo 15.6" IdeaPad 1 Laptop, AMD Dual-core Processor, 15.6" HD Anti-Glare Display, Wi-Fi 6 and Bluetooth 5.0, HDMI, Windows 11 Home in S Mode(20GB RAM | 1TB SSD)',
          description: [
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-1.png",
          imgages: ["main-1.png", "main-1-1.png"],
          type: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 12758,
          installmentPrice: 1300,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "samsung-labtop-2",
          name: "SamSung  Electric SRT96RMBP 3kVA 96V Smart UPS SRT BTRY PK 12 Power Supply SRT96RMBP",
          description: [
            ` an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-2.png",
          imgages: ["main-2.png", "main-1-1.png"],
          itemCategory: "",
          brand: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 50097,
          installmentPrice: 2250,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "dell-labtop-3",
          name: 'Lenovo 15.6" IdeaPad 1 Laptop, AMD Dual-core Processor, 15.6" HD Anti-Glare Display, Wi-Fi 6 and Bluetooth 5.0, HDMI, Windows 11 Home in S Mode(20GB RAM | 1TB SSD)',
          description: [
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-1.png",
          imgages: ["main-1.png", "main-1-1.png"],
          type: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 13684,
          installmentPrice: 1235,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "samsung-labtop-4",
          name: "SamSung  Electric SRT96RMBP 3kVA 96V Smart UPS SRT BTRY PK 12 Power Supply SRT96RMBP",
          description: [
            ` an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-2.png",
          imgages: ["main-2.png", "main-1-1.png"],
          itemCategory: "",
          brand: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 50097,
          installmentPrice: 2250,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "lenovo-labtop-5",
          name: 'Lenovo 15.6" IdeaPad 1 Laptop, AMD Dual-core Processor, 15.6" HD Anti-Glare Display, Wi-Fi 6 and Bluetooth 5.0, HDMI, Windows 11 Home in S Mode(20GB RAM | 1TB SSD)',
          description: [
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-1.png",
          imgages: ["main-1.png", "main-1-1.png"],
          type: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 12758,
          installmentPrice: 1300,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "samsung-labtop-6",
          name: "SamSung  Electric SRT96RMBP 3kVA 96V Smart UPS SRT BTRY PK 12 Power Supply SRT96RMBP",
          description: [
            ` an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-2.png",
          imgages: ["main-2.png", "main-1-1.png"],
          itemCategory: "",
          brand: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 50097,
          installmentPrice: 2250,
          installmentPriceSuffix: "EGP/mo",
        },

        {
          _id: "lenovo-labtop-1",
          name: 'Lenovo 15.6" IdeaPad 1 Laptop, AMD Dual-core Processor, 15.6" HD Anti-Glare Display, Wi-Fi 6 and Bluetooth 5.0, HDMI, Windows 11 Home in S Mode(20GB RAM | 1TB SSD)',
          description: [
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-1.png",
          imgages: ["main-1.png", "main-1-1.png"],
          type: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 12758,
          installmentPrice: 1300,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "samsung-labtop-2",
          name: "SamSung  Electric SRT96RMBP 3kVA 96V Smart UPS SRT BTRY PK 12 Power Supply SRT96RMBP",
          description: [
            ` an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-2.png",
          imgages: ["main-2.png", "main-1-1.png"],
          itemCategory: "",
          brand: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 50097,
          installmentPrice: 2250,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "dell-labtop-3",
          name: 'Lenovo 15.6" IdeaPad 1 Laptop, AMD Dual-core Processor, 15.6" HD Anti-Glare Display, Wi-Fi 6 and Bluetooth 5.0, HDMI, Windows 11 Home in S Mode(20GB RAM | 1TB SSD)',
          description: [
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-1.png",
          imgages: ["main-1.png", "main-1-1.png"],
          type: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 13684,
          installmentPrice: 1235,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "samsung-labtop-4",
          name: "SamSung  Electric SRT96RMBP 3kVA 96V Smart UPS SRT BTRY PK 12 Power Supply SRT96RMBP",
          description: [
            ` an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-2.png",
          imgages: ["main-2.png", "main-1-1.png"],
          itemCategory: "",
          brand: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 50097,
          installmentPrice: 2250,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "lenovo-labtop-5",
          name: 'Lenovo 15.6" IdeaPad 1 Laptop, AMD Dual-core Processor, 15.6" HD Anti-Glare Display, Wi-Fi 6 and Bluetooth 5.0, HDMI, Windows 11 Home in S Mode(20GB RAM | 1TB SSD)',
          description: [
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-1.png",
          imgages: ["main-1.png", "main-1-1.png"],
          type: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 12758,
          installmentPrice: 1300,
          installmentPriceSuffix: "EGP/mo",
        },
        {
          _id: "samsung-labtop-6",
          name: "SamSung  Electric SRT96RMBP 3kVA 96V Smart UPS SRT BTRY PK 12 Power Supply SRT96RMBP",
          description: [
            ` an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
          ],
          main_img: "main-2.png",
          imgages: ["main-2.png", "main-1-1.png"],
          itemCategory: "",
          brand: "",
          status: "New",
          category: "PCs & Laptops",
          cashPrice: 50097,
          installmentPrice: 2250,
          installmentPriceSuffix: "EGP/mo",
        },
      ],
    },
    search: {
      title: "Search",
      inputPlaceHolder: `ex: Switch, Headphones...`,
      searchPrefix: `Search results for `,
      searchSuffix: ` item(s)`,
      button: {
        text: "Saved Items",
        icon: "fa-solid fa-heart",
      },
    },
    filterData: {
      title: "Filter the results by",
      filters: [
        {
          title: "Brands",
          key: "brand",
        },
        {
          title: "Status",
          key: "status",
        },
        // {
        //   title: "Type",
        //   data: [
        //     { name: "type one" },
        //     { name: "type two" },
        //     { name: "type three" },
        //     { name: "type four" },
        //   ],
        // },
      ],
      buttonText: "Applay",
    },
    savedItems: {
      title: "Your Saved Items",
      icon: "fa-solid fa-heart",
      backButtonText: "Back",
      empty: {
        title: "There’s no saved items yet...",
        description: "Try saving your items to compare between them",
        img_url: "empty-saved-items.png",
      },
    },
  },
  fristLoader: true,
  welcomeMsg: `Welcome`,
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setNavVisability: (state, action) => {
      return {
        ...state,
        nav: { ...state.nav, collapse: action.payload },
      };
    },
    hideWelcomeMsg: (state, action) => {
      return {
        ...state,
        fristLoader: false,
      };
    },
  },
});

export const { setNavVisability, hideWelcomeMsg } = rootSlice.actions;

export default rootSlice.reducer;
