import { createSlice } from "@reduxjs/toolkit";
import { getSavedItems, saveProduct, unSaveProduct } from "../../utils/helper";

const initialState = [];

const savedItemsSlice = createSlice({
  name: "savedItems",
  initialState,
  reducers: {
    loadSavedItems: (state) => {
      return [...state, ...getSavedItems()];
    },
    addProduct: (state, { payload }) => {
      saveProduct(payload);
      return [...state, payload];
    },
    removeProduct: (state, { payload }) => {
      unSaveProduct(payload);
      return state.filter((id) => id !== payload);
    },
  },
});

export const { loadSavedItems,addProduct, removeProduct } = savedItemsSlice.actions;

export default savedItemsSlice.reducer;
