import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";

const ApiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: ["services", "works", "categories", "products", "clients"],
  endpoints: (builder) => ({
    getServices: builder.query({
      query: ({ url = `service`, params }) => ({
        url,
        params,
      }),
      providesTags: ["services"],
    }),
    getClients: builder.query({
      query: ({ url = `client`, params }) => ({
        url,
        params,
      }),
      providesTags: ["clients"],
    }),
    getWorks: builder.query({
      query: ({ url = `our-work`, params }) => ({
        url,
        params,
      }),
      providesTags: ["our-work"],
    }),
    getCategories: builder.query({
      query: ({ url = `category`, params }) => ({
        url,
        params,
      }),
      providesTags: ["categories"],
    }),
    getProducts: builder.query({
      query: ({ url = `product`, params }) => ({
        url,
        params,
      }),
      providesTags: ["products"],
    }),
    getProductById: builder.query({
      query: ({ id, params }) => ({
        url: `product/${id}`,
        params,
      }),
      providesTags: ["products"],
    }),
    sendContactInfo: builder.mutation({
      query: (body) => ({
        url: `contact-us`,
        method:'POST',
        body,
      }),
    }),
  }),
});

export default ApiSlice;
export const {
  useGetServicesQuery,
  useGetClientsQuery,
  useGetWorksQuery,
  useGetCategoriesQuery,
  useGetProductsQuery,
  useGetProductByIdQuery,
  useSendContactInfoMutation
} = ApiSlice;
