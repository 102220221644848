import React from "react";
import Logo from "../assets/logo.png";
import rightImage from "../assets/right_corner.png";
import leftImage from "../assets/left_corner.png";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {setNavVisability} from '../store/StaticDataReducer'
function Nav() {
  const nav = useSelector((state) => state.root.nav);
  const dispatch = useDispatch()
  return (
    <nav className="navbar">
      <img src={leftImage} alt="bg-left" className="background_image left" />
      <img src={rightImage} alt="bg-right" className="background_image right" />
      <div className="logo">
        <img src={Logo} alt="TopLink Logo" />
      </div>
      <ul className={`${nav.collapse ? "collapse" : ""}`}>
        {nav.links.map(({ value, route }) => {
          return (
            <li key={value}>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={route}
                onClick={()=>{dispatch(setNavVisability(!nav.collapse))}}
              >
                {value}
              </NavLink>
            </li>
          );
        })}
      </ul>
      <div
        className="collapse-icon"
        onClick={() => {
          dispatch(setNavVisability(!nav.collapse))
        }}
      >
        <i className="fa-solid fa-list"></i>
      </div>
    </nav>
  );
}

export default Nav;
