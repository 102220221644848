import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import RoutersContainer from "./components/RoutersContainer";
const Home = React.lazy(() => import("./pages/Home"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const Services = React.lazy(() => import("./pages/Services"));
const Shop = React.lazy(() => import("./pages/Shop"));
const RecentWork = React.lazy(() => import("./pages/RecentWork"));
const Clients = React.lazy(() => import("./pages/Clients"));
const Partners = React.lazy(() => import("./pages/Partners"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const SavedItems = React.lazy(() => import("./pages/SavedItems"));
const ProductDetails = React.lazy(() => import("./pages/ProductDetails"));

const wrapPage = (Page, isShop = false) => (
  <>
    <Nav />
    <RoutersContainer>
      <Page />
    </RoutersContainer>
    <Footer isShop={isShop} />
  </>
);

const Routers = createBrowserRouter([
  {
    path: "/",
    element: wrapPage(Home),
  },
  {
    path: "/about-us",
    element: wrapPage(AboutUs),
  },
  {
    path: "/services",
    element: wrapPage(Services),
  },
  {
    path: "/shop",
    element: wrapPage(Shop, true),
  },
  {
    path: "/shop/saved",
    element: wrapPage(SavedItems, true),
  },
  {
    path: "/shop/:productId",
    element: wrapPage(ProductDetails, true),
  },
  {
    path: "/recentwork",
    element: wrapPage(RecentWork),
  },
  {
    path: "/clients",
    element: wrapPage(Clients),
  },
  {
    path: "/partners",
    element: wrapPage(Partners),
  },
  {
    path: "/contactUs",
    element: wrapPage(ContactUs),
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

export default Routers;
