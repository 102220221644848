import React from "react";
import ReactDOM from "react-dom/client";
import Routers from "./Routers";
import Loader from "./components/Loader";
import { RouterProvider } from "react-router-dom";
import store from "./store/store";
import { Provider } from "react-redux";
import "./styles/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <React.Suspense fallback={<Loader />}>
      <section className="router-container">
        <RouterProvider
          router={Routers}
          fallbackElement={<Loader />}
          className="router-container"
        />
      </section>
    </React.Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
