import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./StaticDataReducer";
import savedItems from "./SavedItems/SavedItemsReducer";
import ApiSlice from "./Api/api";
const store = configureStore({
  reducer: {
    root: rootReducer,
    savedItems: savedItems,
    api: ApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ApiSlice.middleware),
});

export default store;
